import React from 'react'
import '../../styles/services.css'
const serviceData=[
    {
        icon:'ri-seo-line',
        title: 'SEO',
        desc: 'SEO stands for "search engine optimization" In simple terms,SEO means the process of improving your website to increase its visibility in Google,Microsoft Bing, and other search engines whenever people search for'
    },
    {
        icon:'ri-rocket-line',
        title: 'SMM',
        desc: ' Social media marketing emphasizes using social media platforms to engage consumers and promote companies. It entails producing and disseminating content, running sponsored ads, and engaging with users on social media sites.'
    },
    {
        icon:'ri-code-line',
        title: 'Web Design',
        desc: 'In digital marketing, web design is all about making websites that are aesthetically pleasing, easy to use, and responsive so that they may complement a brand online presence and marketing objectives.'
    },
    {
        icon:'ri-advertisement-line',
        title: 'Google Ads',
        desc: 'Companies can make advertisements that show up on YouTube, mobile applications, websites, and Google search results.It helps businesses reach potential customers and drive traffic to their sites.'
    },

]
const services = () => {
  return <section id ="service" className="service">
    <div className="container">
        <div className="serviceTopContent">
            <h6 className= "subtitle">Our Services</h6>
            <h2>Boost your business output with</h2>
            <h2 className="highlight">Our top-notch services</h2>
        </div>
        <div className="serviceItemwrapper">
  {
    serviceData.map((item, index) => (
      <div className="serviceItem" key={index}>
        <span className="serviceIcon">
          <i className={item.icon}></i>
        </span>
        <h3 className="serviceTitle">{item.title}</h3>
        <p className="description" style={{ color: 'white' }}>{item.desc}</p> {/* Added inline style */}
      </div>
    ))
  }
</div>


    </div>
  </section>
}

export default services
