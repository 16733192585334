import React from 'react'
import '../../styles/counter.css'
const counterData = [
    {
        number: '500',
        text:'Clients'
    },
    {
        number: '2k',
        text:'Projects'
    },{
        number: 30,
        text:'Team Member'
    }
]
const counter = () => {
  return <section className="counter"id ="projects">
    <div className="container">
        <div className="counterwrapper">
            {
                counterData.map((item,index)=>(
                    <div className="counterItem"key ={index}>
                <h3 className="counterNumber">{item.number}+</h3>
                <h4 className="counterTitle">{item.text}</h4>
            </div>
                ))
            }
           
        </div>
    </div>
  </section>
}

export default counter

