import '../../styles/contact.css'
import React from 'react'

const contact = () => {
  
  return (
   <section id ="contact"className="contact">
    <form>
      <h2>Contact Form</h2>
      <div className="input-box">
      <label style={{ color: 'white' }}>Full Name</label>
        <input type ="text" className="field" placeholder = 'Enter Your Name' required />
      </div>
      <div className="input-box">
      <label style={{ color: 'white' }}>Business Name</label>
        <input type ="text" className="field" placeholder = 'Enter Your Business Name' required />
      </div>
      <div className="input-box">
      <label style={{ color: 'white' }}>Contact Number</label>
        <input type ="text" className="field" placeholder = 'Enter Your Contact number' required />
      </div>
      <div className="input-box">
        <label style={{ color: 'white' }}>Email Address</label>
        <input type ="email" className="field" placeholder = 'Enter Your Email' required/>
      </div>
      <div className="input-box">
        <label style={{ color: 'white' }}>Your Message</label>
        <textarea name ="" id ="" className="field message-field"placeholder = 'Enter your message ' required ></textarea>
      </div>
      <button type = "Submit">Send Message</button>
    </form>
   </section>
  )
}

export default contact
