import React from 'react'
import "../../styles/About.css";
const chooseData = [
  {
    icon: 'ri-wifi-line',
    title: 'First Working Process',
    desc : 'Our first working process of a digital marketing agency typically involves understanding the clients business and goals. The agency then develops a strategic plan tailored to the client’s objectives,Performing a detailed analysis of the clients target market, competitors, and present market position is part of this first phase. After that, the agency creates a strategy plan that outlines the main marketing initiatives and media to be employed and is customized to the clients goals. This initial phase guarantees that all subsequent marketing endeavors are in line with the objectives of the client and are planned to yield quantifiable outcomes.'
  },
  {
    icon: 'ri-speak-fill',
    title: 'Our Vision',
    desc : 'Our approach combines data-driven analysis with creative flair, ensuring your brand not only reaches the right people but also leaves a lasting impact. From boosting your search engine rankings to creating compelling social media campaigns, we are dedicated to helping you thrive in a competitive digital landscape.'
  },
  {
    icon: 'ri-team-line',
    title: 'Our Team',
    desc : 'We enjoy working together and coming up with new ideas. We get to know each customer well in order to meet their marketing objectives. We keep ahead of market trends and deliver excellent service because of our dedication to excellence and ongoing learning.'
  },
]
const About = () => {
  return <section id ="about" className="about">
    <div className="container">
        <div className="aboutwrapper">
           <div className="aboutContent">
            <h6 className="subtitle"> Why Choose Us </h6>
            <h2>We provide high-quality services at competitive prices,ensuring you get the  </h2>
            <h2 className="highlight">best value for your investment</h2>
            <p className="descriptionAbout_content">
              Our group is prepared to help you advance toward your objectives. In addition to creativity, experience, and a well-equipped web tool, our marketing team makes use of facts and data. We offer end-to-end solutions, so we fully address your needs. Let's join hands and let us assist you connect the dots.
            </p>
            <div className='chooseitem_wrapper'>
  {
    chooseData.map((item, index) => (
      <div className="chooseUsItem" key={index}>
        <span className="chooseUs_Icon"><i className={item.icon}></i></span>
        <div>
          <h4 className="chooseUs_title">{item.title}</h4>
          <p className="description">{item.desc}</p>
        </div>
      </div>
    ))
  }
</div>

           </div>
            <div className="aboutImg"></div>   
        </div>
    </div>
  </section>
}

export default About
