
import './App.css';
import Header from "./Components/pages/Header"
import Intro from "./Components/UI/Intro";
import Counter from "./Components/UI/counter"
import Services from "./Components/UI/services"
import Team from "./Components/UI/Team";
import Testimonial from "./Components/UI/Testimonial"
import Contact from "./Components/UI/contact"
import Footer from "./Components/pages/footer"
import About from "./Components/UI/About"
function App() {
  return (
    <>
    <Header/>
    <Intro/>
    <Counter/>
    <Services/>
    <Team/>
    <About/>
    <Testimonial/>
    <Contact/>
    <Footer/>
    </>
  );
}

export default App;
