import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-section left">
        <h4>RCS</h4>
        <p>Let's collaborate and turn your digital marketing challenges into opportunities for growth and success.</p>
      </div>
      <div className="footer-section center">
        <h3>Quick Links</h3>
        <ul>
          <li><a href="#home">Home</a></li>
          <li><a href="#service">Services</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#contact">Contact Us</a></li>
        </ul>
      </div>
      <div className="footer-section right">
        <h3>Contact Info</h3>
        <p>Email: info@gmail.com</p>
        <p>Phone: 987664743455677</p>
        <p>Address: Kamalpokhari, Kathmandu</p>
      </div>
      <div className="footer-section map">
        <h3>Find Us</h3>
        <iframe
  title="Kamal Pokhari Map"
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.2122662550264!2d85.32268482510644!3d27.710731726180576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1908c874a40b%3A0x87a26cbf3b75037c!2sKamal%20Pokhari!5e0!3m2!1sen!2snp!4v1726331927481!5m2!1sen!2snp"
  width="600"
  height="450"
  style={{ border: 0 }}  
  allowFullScreen=""
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
></iframe>

      </div>
      <div className="footer-section copyright">
        <p>&copy; 2024 RCS. All Rights Reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
