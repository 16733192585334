import React from 'react'
import '../../styles/Intro.css'
import introImg from '../images/pic 3.jpeg'
const Intro = () => {
  return <section id ="home" classname ='intro-section'>
    <div className="container">
      <div className="introwrapper">
        <div className="introcontent">
          <div>
          <h2>The Future of  </h2>
          <h2>Marketing is here</h2>
          <h2 className="highlight">Promote your Brand</h2>
          </div>
          <p className="descr"> At RCS , we specialize in creating powerful digital marketing strategies that will elevate your brand's presence online. From SEO and content marketing to social media management, our expert team is here to help you achieve your business goals.</p>
          <div className= "intro-btns">
            <button className="primary">Let's Start</button>
            <button className="secondary_btn">Get a Proposal</button>
          </div>
        </div>
        <div className="intro_img">
          <img src={introImg } alt="pic 3"/>
        </div>
      </div>
    </div>
  </section>
}

export default Intro
