import React from 'react'
import '../../styles/Testimonial.css'
import Slider from "react-slick"
import pic4 from "../images/pic 4.jpeg"
const Testimonial = () => {
    const settings={
        dots: false,
        infinite: true,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToslide: true,

    };
  return (
    <section>
        <div className="container">
            <div className="sliderContentTop">
                <h6 className="subtitle">Testimonial</h6>
                <h2>Trusted by more than <span className="highlight">5,000 customers</span></h2>
            </div>
            <div className="sliderWrapper">
            <Slider {...settings}>
        <div className="sliderItem">
            <p className="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, nisi nemo. Atque, libero qui quisquam doloribus id modi placeat repudiandae est autem laudantium sunt numquam quis et asperiores nam, necessitatibus ad reiciendis hic facere voluptatem illo consequuntur officiis repellat assumenda.</p>
            <div className="customerDetails">
                <div className="customerImg">
                    <img src={pic4} alt=""/>
                </div>
                <div>
                    <h5 className="customerName">John Doe</h5>
                    <p className="description">CEO, Marketing</p>
                </div>
            </div>

        </div>
        <div className="sliderItem">
            <p className="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, nisi nemo. Atque, libero qui quisquam doloribus id modi placeat repudiandae est autem laudantium sunt numquam quis et asperiores nam, necessitatibus ad reiciendis hic facere voluptatem illo consequuntur officiis repellat assumenda.</p>
            <div className="customerDetails">
                <div className="customerImg">
                    <img src={pic4} alt=""/>
                </div>
                <div>
                    <h5 className="customerName">Tom Smith</h5>
                    <p className="description">CEO, Marketing</p>
                </div>
            </div>

        </div>
        <div className="sliderItem">
            <p className="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, nisi nemo. Atque, libero qui quisquam doloribus id modi placeat repudiandae est autem laudantium sunt numquam quis et asperiores nam, necessitatibus ad reiciendis hic facere voluptatem illo consequuntur officiis repellat assumenda.</p>
            <div className="customerDetails">
                <div className="customerImg">
                    <img src={pic4} alt=""/>
                </div>
                <div>
                    <h5 className="customerName">Alice Key</h5>
                    <p className="description">CEO, Marketing</p>
                </div>
            </div>

        </div>
        <div className="sliderItem">
            <p className="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, nisi nemo. Atque, libero qui quisquam doloribus id modi placeat repudiandae est autem laudantium sunt numquam quis et asperiores nam, necessitatibus ad reiciendis hic facere voluptatem illo consequuntur officiis repellat assumenda.</p>
            <div className="customerDetails">
                <div className="customerImg">
                    <img src={pic4} alt=""/>
                </div>
                <div>
                    <h5 className="customerName">Rita </h5>
                    <p className="description">CEO, Marketing</p>
                </div>
            </div>

        </div>
    </Slider>
            </div>
        </div>
    </section>
  );
};

export default Testimonial
