import React from 'react';
import '../../styles/Team.css';
import teams from '../images/picture.jpeg';

const teamMembers = [
    {
        imgUrl: teams,
        name: 'Lisa Smith',
        position: 'Full stack developer',
        description: 'Lisa is a skilled full-stack developer with over 5 years of experience in building scalable applications.'
    },
    {
        imgUrl: teams,
        name: 'John Doe',
        position: 'Backend Developer',
        description: 'John specializes in backend systems, ensuring smooth database management and server operations.'
    },
    {
        imgUrl: teams,
        name: 'Jane White',
        position: 'Frontend Developer',
        description: 'Jane has a keen eye for UI/UX design and is responsible for delivering sleek and responsive user interfaces.'
    },
    {
        imgUrl: teams,
        name: 'Michael Brown',
        position: 'DevOps Engineer',
        description: 'Michael is passionate about automation and infrastructure, making sure that everything runs smoothly.'
    }
];

const Team = () => {
    return (
        <section className="OurTeam">
            <div className="container">
                <div className="teamContent">
                    <h6 className="subtitle">Our Team</h6>
                    <h2>Meet with <span className="highlight">Our Team</span></h2>
                </div>
                <div className="teamWrapper">
                    {teamMembers.map((item, index) => (
                        <div className="teamItem" key={index}>
                            <div className="teamImg">
                                <img src={item.imgUrl} alt={item.name} />
                            </div>
                            <div className="teamDetails">
                                <h4>{item.name}</h4>
                                <p className="position">{item.position}</p>
                                <p className="description">{item.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Team;
