import React ,{useRef,useEffect}from 'react'
import './Header.css';
const navLinks =[{
    path: '#home',
    display:'Home',
},
{
    path: '#service',
    display:'Service',
},
{
    path: '#about',
    display:'About',
},{
    path: '#contact',
    display:'Contact Us',
}
]
const Header = () => {
    const headerRef = useRef(null)
    const headerFunc = ()=>{
        if(document.body.scrollTop> 80 || document.documentElement.scrollTop>80){
            headerRef.current.classList.add('header_shrink')
        }
        else{
            headerRef.current.classList.remove('header_shrink')
        }
    }
    useEffect(()=>{
        window.addEventListener('scroll',headerFunc)
        return ()=> window.removeEventListener('scroll',headerFunc)
    },[]);
    const handleClick = e =>{
       e.preventDefault()
       const targetAttr = e.target.getAttribute('href')
       const location = document.querySelector(targetAttr).offsetTop;
       window.scrollTo({
        left: 0, 
        top: location - 80,
       });
    };
  return (
    <header className ="Header" ref = {headerRef}>
        <div className ="container">
            <div className="navwrapper">
            <div className="logo" style={{ textAlign: 'center', padding: '10px' }}>
            <h2 style={{ fontSize: '3rem', margin: '0', color: 'orange' }}>RCS </h2>
         
          </div>
                <div className ="Nav">
                <ul className ="menus">
                    {navLinks.map((item,index)=>(
                        <li className = "menus_item" key ={index}>
                            <a
                             href ={item.path}  onClick ={handleClick}className="menus_link">
                                {item.display}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
            </div>
           

        </div>
    </header>

  )
}

export default Header

